import { useMemo, useState } from 'react';
import { UCCSearchOptionType } from './types';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';

export const useViewAllRedirectUrl = (ucc1Number: string) => {
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const { rn, searchOptionType, searchOptionSubOption, searchCategory, text } =
    useSearchPageQueryParams();

  useMemo(() => {
    if (searchOptionType === UCCSearchOptionType.DocumentNumber) {
      setRedirectUrl(`?uccNumber=${ucc1Number}&searchOptionType=${searchOptionType}`);
    } else {
      setRedirectUrl(
        `?rn=${rn}&searchOptionType=${searchOptionType}&searchOptionSubOption=${searchOptionSubOption}&searchCategory=${searchCategory}&text=${text}`
      );
    }
  }, [searchOptionType, rn, ucc1Number]);

  return { redirectUrl };
};
