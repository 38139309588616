import { API } from 'aws-amplify';
import { publicSearchApiName } from '../../common/constants/api';
import { ResponseMessageType } from '../shared/types';
import {
  DebtorSearchResponse,
  FileDetailsInterface,
  FilingHistoryInterface,
  SearchOptionType,
  SearchResultsFetchParams,
  UCCSearchOptionType,
} from './types';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';

export const getFileDetails = async (
  searchOptionType: string,
  document?: string,
  rn?: string,
  searchOptionSubOption?: string,
  searchCategory?: string,
  text?: string
): Promise<ResponseMessageType<FileDetailsInterface>> => {
  let qsParams: Record<string, string | null | undefined> = {
    searchOptionType,
  };

  if (searchOptionType === UCCSearchOptionType.DocumentNumber) {
    qsParams = {
      ...qsParams,
      filingNumber: document ?? null,
    };
  } else {
    qsParams = {
      ...qsParams,
      rowNumber: rn?.toString() ?? null,
      searchOptionSubOption: searchOptionSubOption ?? null,
      searchCategory: searchCategory,
      text: text ?? null,
    };
  }

  if (searchCategory === 'undefined') {
    delete qsParams.searchCategory;
  }

  return await API.get(publicSearchApiName, '/filing-details', {
    queryStringParameters: qsParams,
  });
};

export const getFilingHistory = async (
  uccNumber: string | undefined,
  currentPage: string | undefined
): Promise<ResponseMessageType<FilingHistoryInterface[]>> => {
  const res = await API.get(publicSearchApiName, '/filing-history', {
    queryStringParameters: {
      pageSize: 3,
      currentPage,
      ucc1Number: uccNumber,
      sortOrder: 'Descending',
      orderColumnName: 'FilingDate',
      secondaryOrderColumnName: 'SeqNumber',
    },
  });

  return res;
};

export const getSearchOptions = async (): Promise<ResponseMessageType<SearchOptionType[]>> => {
  return await API.get(publicSearchApiName, '/search-types', {});
};

export const getFilingsCompletedThroughDate = async (): Promise<ResponseMessageType<string>> => {
  return await API.get(publicSearchApiName, '/filings-completed-through-date', {});
};

export const getSearchResults = async (
  params: SearchResultsFetchParams
): Promise<ResponseMessageType<DebtorSearchResponse>> => {
  return await API.get(publicSearchApiName, '/search', { queryStringParameters: params });
};
export const getDocumentDownloadUrl = async (
  document?: string
): Promise<ResponseMessageType<string>> => {
  return await API.get(publicSearchApiName, '/filing-image', {
    queryStringParameters: { uccNumber: document },
  });
};
