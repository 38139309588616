import { useEffect, useState } from 'react';
import { useWatch, Controller } from 'react-hook-form';
import {
  Box,
  Grid,
  FormHelperText,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  useTheme,
  useMediaQuery,
  Theme,
  ClickAwayListener,
  Button,
  InputLabel,
  SvgIcon,
} from '@mui/material';

import { StyledTextAreaInput } from '../../../common/components/inputs';
import { CustomTooltip } from '../../../common/components/tooltip/CustomTooltip';
import { StyledStartUccRadioButton } from '../../start-ucc-filing/styled-items/StyledStartUccFilingRadioButton';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

import { AltDesignationType, DocStampTaxType } from '../models';
import { IFormUI } from '../../../common/models';
import { FormDataUCC1 } from '../schema';
import { getFloridaDocStampTax } from '../ucc1.services';
import { parseErrorMessages } from '../../../common/helpers';

interface ICollateralInformation extends IFormUI<FormDataUCC1> {
  designationTypes: AltDesignationType[] | null;
  docStampTax: DocStampTaxType[] | null;
}

const CollateralInformation = ({
  control,
  formState: { errors },
  designationTypes,
  docStampTax,
}: ICollateralInformation) => {
  const theme = useTheme();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [floridaDocStampTax, setFloridaDocStampTax] = useState('');

  useEffect(() => {
    (async () => {
      const res = await getFloridaDocStampTax();
      if (res.notOk) {
        throw new Error(parseErrorMessages(res.messages));
      } else {
        setFloridaDocStampTax(res);
      }
    })();
  }, []);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const [financingStatement, filerRefData] = useWatch({
    control,
    name: ['financingStatement', 'filerRefData'],
  });

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        padding: `${theme.convert.pxToRem(28)} ${theme.convert.pxToRem(0)} ${theme.convert.pxToRem(
          40
        )}`,
      }}
    >
      <Grid
        container
        sx={{
          paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
          paddingRight: { xs: theme.convert.pxToRem(28), md: theme.convert.pxToRem(40) },
        }}
        direction="column"
        gap={2}
      >
        <Grid container gap={1}>
          <Grid item xs={12} sx={{ paddingBottom: { xs: 1 } }}>
            <InputLabel htmlFor="financingStatement">
              <Typography variant="h5">
                This Financing Statement covers the following collateral (Additional collateral
                description can be entered in Addendum Page):
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} xl={9} position="relative">
            <Controller
              name="financingStatement"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.financingStatement?.message} fullWidth>
                  <StyledTextAreaInput
                    {...field}
                    minRows={3}
                    error={!!errors.financingStatement?.message}
                    placeholder="Enter collateral description"
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      right: theme.convert.pxToRem(10),
                      bottom: theme.convert.pxToRem(5),
                      fontSize: theme.typography.pxToRem(12),
                      color: theme.palette.grey[500],
                    }}
                  >
                    {financingStatement?.length ?? 0} / 15,000 characters
                  </Typography>
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                color: (theme) => theme.palette.grey[500],
                fontSize: theme.typography.pxToRem(14),
                fontWeight: 400,
                lineHeight: theme.typography.pxToRem(20),
              }}
            >
              Note: Collateral descriptions that exceed the maximum field size will be recorded on
              Additional Information form(s) for a fee of $3.00/form.
            </FormHelperText>
            <FormHelperText
              sx={{ textTransform: 'none' }}
              error={!!errors.financingStatement?.message}
            >
              {errors.financingStatement?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} xs={12} sx={{ paddingBottom: 1 }}>
            <InputLabel htmlFor="altDesignationType">
              <Typography variant="h5">Alternate Designation (if applicable):</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} xl={9}>
            <Controller
              control={control}
              name="altDesignationType"
              render={({ field }) => (
                <FormControl required error={!!errors.altDesignationType?.message} fullWidth>
                  <RadioGroup {...field}>
                    <Grid container minWidth={290}>
                      {designationTypes?.map((item: any) => {
                        return (
                          <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                            <FormControlLabel
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  whiteSpace: 'nowrap',
                                },
                              }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.altDesignationType?.message}>
              {errors.altDesignationType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ paddingBottom: 1 }}>
            <Box display="flex" alignItems="center">
              <InputLabel htmlFor="docStampTaxType" sx={{ whiteSpace: 'normal' }}>
                <Typography variant="h5" minWidth={{ sm: 270 }}>
                  Florida DOCUMENTARY STAMP TAX:
                </Typography>
              </InputLabel>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <CustomTooltip
                  onClose={handleTooltipClose}
                  open={openTooltip}
                  placement={isSmallScreen ? 'left-start' : 'right-start'}
                  disableHoverListener
                  title={<Typography variant="body2">{floridaDocStampTax}</Typography>}
                >
                  <Button
                    TouchRippleProps={{
                      style: {
                        width: '60%',
                        height: '100%',
                        left: '20%',
                      },
                    }}
                    onClick={handleTooltipOpen}
                    style={{ display: 'flex', justifyContent: 'start' }}
                  >
                    <SvgIcon fontSize="inherit">
                      <InfoIcon color={theme.palette.info.main} />
                    </SvgIcon>
                  </Button>
                </CustomTooltip>
              </ClickAwayListener>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Controller
              control={control}
              name="docStampTaxType"
              render={({ field }) => (
                <FormControl sx={{ p: 0 }} required error={!!errors.docStampTaxType?.message}>
                  <RadioGroup {...field}>
                    <Grid container>
                      {docStampTax?.map((item: any) => {
                        return (
                          <Grid item key={item.id} xs={12}>
                            <FormControlLabel
                              sx={{ display: 'flex', alignItems: 'baseline' }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.docStampTaxType?.message}>
              {errors.docStampTaxType?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container gap={1}>
          <Grid item xs={12} sx={{ paddingBottom: 1 }}>
            <InputLabel htmlFor="filerRefData">
              <Typography variant="h5">Optional Filer Reference Data:</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={12} xl={9} position="relative">
            <Controller
              name="filerRefData"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.filerRefData?.message} fullWidth>
                  <StyledTextAreaInput
                    {...field}
                    placeholder="Enter optional filer reference data"
                    error={!!errors.filerRefData?.message}
                    minRows={3}
                  />
                </FormControl>
              )}
            />
            <Typography
              sx={{
                position: 'absolute',
                right: theme.convert.pxToRem(10),
                bottom: `${
                  errors.filerRefData?.message
                    ? theme.convert.pxToRem(25)
                    : theme.convert.pxToRem(5)
                }`,
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.grey[500],
              }}
            >
              {filerRefData?.length ?? 0} / 50 characters
            </Typography>
            <FormHelperText error={!!errors.filerRefData?.message}>
              {errors.filerRefData?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CollateralInformation;
