export enum Accordion {
  SendAcknowledgementTo = 0,
  Debtors,
  SecuredParties,
  CollateralInformation,
  Addendum,
  Attachments,
}

export const accordionExpandAllState = [true, true, true, true, true, true];
export const accordionDefaultState = [true, true, true, true, false, true];
export const accordionCollapseAllState = [false, false, false, false, false, false];
