import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Skeleton, useTheme } from '@mui/material';

import Loader from '../common/components/loader';
import PageWrapper from '../common/layouts/PageWrapper';
import FilingInfo from '../features/search/details/FilingInfo';
import Navigation from '../features/search/details/Navigation';
import { gridLoadingBackdrop } from '../common/constants/colors';
import { PageTitle } from '../common/constants/features-constants';
import FilingHistory from '../features/search/details/FilingHistory';
import DebtorParties from '../features/search/details/DebtorParties';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import SecuredParties from '../features/search/details/SecuredParties';
import DocumentImages from '../features/search/details/DocumentImages';
import { useDetailsPage } from '../features/search/details/use-details-page';
import { ReactComponent as BackArrow } from '../assets/icons/arrow-back.svg';
import SearchResultsHeading from '../features/search/details/SearchResultsHeading';
import { StyledTableContainer } from '../features/search/details/StyledComponents';
import { useSearchPageQueryParams } from '../features/search/useSearchPageQueryParams';
import { UCCSearchOptionType } from '../features/search/types';
import DetailsSkeleton from '../features/search/details/DetailsSkeleton';

export const UCCSearchDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { searchOptionType } = useSearchPageQueryParams();
  const { fileData, isLoading, nextUrl, prevUrl, backUrl, canHaveNavigation } = useDetailsPage();

  if (isLoading && !fileData && searchOptionType !== UCCSearchOptionType.DocumentNumber) {
    return (
      <Box display={'flex'} justifyContent={'center'} marginTop={10}>
        <Loader color="blue" size={50} />
      </Box>
    );
  }

  return (
    <DisclaimerWrapper>
      <PageWrapper title="UCC Search" headTitle={PageTitle.SEARCH_RESULTS_DETAIL}>
        <>
          <Box marginBottom={theme.convert.pxToRem(20)}>
            <Button onClick={() => navigate(backUrl)} startIcon={<BackArrow />}>
              Back
            </Button>
          </Box>
          <StyledTableContainer>
            {isLoading && searchOptionType !== UCCSearchOptionType.DocumentNumber && (
              <Box
                zIndex={1}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                position={'absolute'}
                alignItems={'center'}
                justifyContent={'center'}
                style={{ backgroundColor: gridLoadingBackdrop }}
              >
                <Loader color="blue" size={50} />
              </Box>
            )}
            <Grid container flexDirection={'column'} minWidth={theme.convert.pxToRem(1140)}>
              {searchOptionType !== UCCSearchOptionType.DocumentNumber || fileData ? (
                <SearchResultsHeading content={`Detail Record For: ${fileData?.uccNumber ?? ''}`} />
              ) : isLoading ? (
                <SearchResultsHeading content={<Skeleton width={335} height={40} />} />
              ) : (
                <SearchResultsHeading content={'Detail Record For:'} />
              )}

              {searchOptionType === UCCSearchOptionType.DocumentNumber &&
                isLoading &&
                !fileData && <DetailsSkeleton />}
              {fileData && (
                <>
                  <FilingInfo fileData={fileData} />
                  <FilingHistory fileData={fileData} />
                  <SecuredParties fileData={fileData} />
                  <DebtorParties fileData={fileData} />
                  <DocumentImages fileData={fileData} />
                </>
              )}
            </Grid>
          </StyledTableContainer>
        </>
        {canHaveNavigation && fileData && <Navigation prevUrl={prevUrl} nextUrl={nextUrl} />}
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
