import PageWrapper from '../common/layouts/PageWrapper';
import { PageTitle } from '../common/constants/features-constants';
import {
  Box,
  BoxProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { formatDate, TimeZone } from '../common/helpers/format-date';
import { StyledUnderlineDownloadButtons } from '../features/download/StyledUnderlineDownloadButtons';
import Loader from '../common/components/loader';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import { Controller } from 'react-hook-form';
import { TextInput } from '../common/components/inputs';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MagnifyingGlassIcon } from '../assets/icons/mag_glass_icon.svg';
import { useSearchPage } from '../features/search/use-search-page';
import { SearchResults } from '../features/search/SearchResults';
import {
  SearchCategory,
  SearchOptionSubOption,
  UCCSearchCategory,
  UCCSearchOptionSubOption,
  UCCSearchOptionType,
} from '../features/search/types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSearchPageQueryParams } from '../features/search/useSearchPageQueryParams';
import logo from '../assets/icons/ucclogo.png';
import { useReactToPrint } from 'react-to-print';

const SEARCH_RULES_DOC_NAME = 'UCC_Debtor_Name_Compaction_and_Search_Rules.pdf';
const downloadSearchRules = () => window.open(SEARCH_RULES_DOC_NAME);

export const StyledSearchBox = styled(Box)<BoxProps>(({ theme }) => ({
  paddingTop: theme.typography.pxToRem(20),
  paddingBottom: theme.typography.pxToRem(20),
  paddingLeft: theme.typography.pxToRem(30),
  marginTop: theme.typography.pxToRem(12),
  marginBottom: theme.typography.pxToRem(16),
  backgroundColor: theme.palette.primary.light,
  gap: theme.typography.pxToRem(10),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.typography.pxToRem(25),
    paddingLeft: theme.typography.pxToRem(25),
  },
}));

export const StyledFormLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  minWidth: theme.typography.pxToRem(200),
  marginRight: 5,
}));
export const UCCSearch = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    options,
    control,
    nextUrl,
    prevUrl,
    getUrl,
    searchOptionTypeValue,
    searchOptionSubTypeValue,
    throughDate,
    handleSubmit,
    searchResults,
    isFetchingSearchResults,
    formState: { errors },
    hasFinishedInitialFetches,
    form,
    lastSearchedKeyword,
  } = useSearchPage();

  const { searchCategory } = useSearchPageQueryParams();

  useEffect(() => {
    if (searchOptionTypeValue !== UCCSearchOptionType.OrganizationDebtorName) {
      form.setValue('searchCategory', undefined);
      return;
    }
    if (searchOptionTypeValue === UCCSearchOptionType.OrganizationDebtorName) {
      if (
        !options
          ?.find((searchOptionType) => searchOptionType?.id === searchOptionTypeValue)
          ?.subOptions?.find((subOpt) => subOpt.id === searchOptionSubTypeValue)?.categories.length
      ) {
        form.setValue('searchCategory', undefined);
      } else {
        if (!searchCategory || searchCategory === 'undefined') {
          form.setValue('searchCategory', UCCSearchCategory.Standard);
        }
      }
    }
  }, [searchOptionTypeValue, searchOptionSubTypeValue, form, options]);

  const categoriesOptions = useMemo(() => {
    return options
      ?.find((optType) => optType?.id === searchOptionTypeValue)
      ?.subOptions?.find((subOpt) => subOpt.id === searchOptionSubTypeValue)?.categories;
  }, [searchOptionTypeValue, searchOptionSubTypeValue, options]);

  const subOptions = useMemo(() => {
    return options?.find((item) => item.id === searchOptionTypeValue)?.subOptions;
  }, [searchOptionTypeValue, options]);

  const canShowCategory = useMemo(() => {
    return (
      searchOptionSubTypeValue !== UCCSearchOptionSubOption.FiledAndLapsedActualDebtorNameList &&
      searchOptionSubTypeValue !== UCCSearchOptionSubOption.FiledActualDebtorNameList &&
      searchOptionSubTypeValue !== UCCSearchOptionSubOption.LapsedActualDebtorNameList
    );
  }, [searchOptionSubTypeValue]);

  const placeholder = useMemo(() => {
    switch (searchOptionTypeValue) {
      case UCCSearchOptionType.DocumentNumber:
        return 'UCC Number';
      case UCCSearchOptionType.IndividualDebtorName:
        return 'Individual Name';
      case UCCSearchOptionType.OrganizationDebtorName:
        return 'Organization Name';
      default:
        return 'Debtor name';
    }
  }, [searchOptionTypeValue]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Search Results`,
  });

  return (
    <DisclaimerWrapper>
      <PageWrapper title={`UCC ${PageTitle.SEARCH}`} headTitle={PageTitle.SEARCH}>
        {!hasFinishedInitialFetches ? (
          <Box display={'flex'} justifyContent={'center'} marginTop={10}>
            <Loader color="blue" size={50} />
          </Box>
        ) : (
          <Box
            ref={componentRef}
            sx={{
              '@media print': {
                '@page': {
                  size: 'landscape',
                },
                paddingRight: 1,
                paddingLeft: 1,
              },
            }}
          >
            <Box
              className="print-only"
              maxWidth={['93.6%', '86.5%', '79.2%']}
              width={['93.6%', '86.5%', '79.2%']}
              marginX="auto"
              marginY={theme.convert.pxToRem(40)}
            >
              <img src={logo} alt="ucc logo" />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Typography
                  borderRight={{ xs: 0, md: 2 }}
                  fontWeight={{ xl: 700, lg: 600 }}
                  variant={isMobile ? 'body2' : 'h5'}
                  paddingRight={theme.typography.pxToRem(11)}
                  borderColor={{ xs: 'black', md: theme.palette.text.disabled }}
                >
                  {`Today's date: ${formatDate(new Date().toISOString())}`}
                </Typography>
                {throughDate && (
                  <Typography
                    fontWeight={{ xl: 700, lg: 600 }}
                    variant={isMobile ? 'body2' : 'h5'}
                    paddingLeft={theme.typography.pxToRem(4)}
                  >
                    {`UCC Filings Completed Through: ${formatDate(throughDate, TimeZone.EST)}`}
                  </Typography>
                )}
              </Box>
              <StyledSearchBox>
                {options?.length && (
                  <Box
                    display={'flex'}
                    flexDirection={{
                      xs: 'column',
                      md: 'row',
                    }}
                    alignItems={{ sm: 'start', md: 'center' }}
                  >
                    <StyledFormLabel>
                      <Typography variant={'body1'}>Select Search Type*</Typography>
                    </StyledFormLabel>
                    <Controller
                      control={control}
                      name="searchOptionType"
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          MenuProps={{ disableScrollLock: true }}
                          {...field}
                          id="searchOptionType"
                          size="small"
                          inputRef={ref}
                          error={!!errors?.searchOptionType?.message}
                          sx={{
                            backgroundColor: theme.palette.background.default,
                            width: {
                              xs: '100%',
                              sm: '300px',
                              md: '400px',
                            },
                          }}
                        >
                          {options?.map((optionType) => {
                            return (
                              <MenuItem key={optionType.id} value={optionType.id}>
                                {optionType.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    ></Controller>
                    {errors.searchOptionType?.message && (
                      <FormHelperText error>
                        {errors.searchOptionType?.message ?? ' '}
                      </FormHelperText>
                    )}
                  </Box>
                )}
                {subOptions && subOptions?.length > 0 && (
                  <Box
                    display={'flex'}
                    flexDirection={{
                      xs: 'column',
                      md: 'row',
                    }}
                    alignItems={{ sm: 'start', md: 'center' }}
                  >
                    <StyledFormLabel>
                      <Typography variant={'body1'}>Sub-option*</Typography>
                    </StyledFormLabel>
                    <Controller
                      control={control}
                      name="searchOptionSubOption"
                      render={({ field: { ref, ...field } }) => (
                        <Box display={'flex'} flexDirection={'column'}>
                          <Select
                            MenuProps={{ disableScrollLock: true }}
                            {...field}
                            id="searchOptionSubOption"
                            size="small"
                            inputRef={ref}
                            error={!!errors?.searchOptionSubOption?.message}
                            sx={{
                              backgroundColor: theme.palette.background.default,
                              width: {
                                xs: '100%',
                                sm: '300px',
                                md: '400px',
                              },
                            }}
                          >
                            {subOptions?.map((subOption: SearchOptionSubOption) => {
                              return (
                                <MenuItem key={subOption.id} value={subOption.id}>
                                  {subOption.text}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors.searchOptionSubOption?.message && (
                            <FormHelperText error>
                              {errors.searchOptionSubOption?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      )}
                    ></Controller>
                  </Box>
                )}
                {canShowCategory &&
                  searchOptionTypeValue === UCCSearchOptionType.OrganizationDebtorName && (
                    <Box
                      display={'flex'}
                      flexDirection={{
                        xs: 'column',
                        md: 'row',
                      }}
                      alignItems={{ sm: 'start', md: 'center' }}
                    >
                      <StyledFormLabel>
                        <Typography variant={'body1'}>Category*</Typography>
                      </StyledFormLabel>
                      <Controller
                        control={control}
                        name="searchCategory"
                        render={({ field: { ref, ...field } }) => (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Select
                              MenuProps={{ disableScrollLock: true }}
                              {...field}
                              id="category"
                              size="small"
                              inputRef={ref}
                              error={!!errors?.searchCategory?.message}
                              sx={{
                                backgroundColor: theme.palette.background.default,
                                minWidth: {
                                  xs: '100%',
                                  sm: '300px',
                                  md: '400px',
                                },
                              }}
                            >
                              {categoriesOptions?.map((category: SearchCategory) => {
                                return (
                                  <MenuItem key={category.id} value={category.id}>
                                    {category.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.searchCategory?.message && (
                              <FormHelperText error>
                                {errors.searchCategory?.message}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                      ></Controller>
                    </Box>
                  )}
                <Box
                  display={'flex'}
                  flexDirection={{
                    xs: 'column',
                    md: 'row',
                  }}
                  alignItems={{ sm: 'start', md: 'center' }}
                >
                  <StyledFormLabel>
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {placeholder === 'UCC Number' ? 'Document Number' : placeholder}*
                    </Typography>
                  </StyledFormLabel>
                  <Controller
                    name="keyword"
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <Box display={'flex'} flexDirection={'column'}>
                          <TextInput
                            sx={{
                              width: {
                                xs: '100%',
                                sm: '300px',
                                md: '400px',
                              },
                            }}
                            {...field}
                            size={'small'}
                            inputRef={ref}
                            error={!!errors.keyword?.message}
                            placeholder={`Enter ${placeholder}`}
                            onKeyUp={(e) => {
                              e.key === 'Enter' && handleSubmit();
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton edge="end" aria-label="search" onClick={handleSubmit}>
                                    <MagnifyingGlassIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.keyword?.message && (
                            <FormHelperText style={{ textTransform: 'none' }} error>
                              {errors.keyword?.message}
                            </FormHelperText>
                          )}
                        </Box>
                      );
                    }}
                  />
                </Box>
              </StyledSearchBox>
              <Box marginBottom={theme.typography.pxToRem(20)}>
                <Typography variant={isMobile ? 'h3' : 'h4'} fontWeight={{ xl: 600, xs: 600 }}>
                  Click{' '}
                  <StyledUnderlineDownloadButtons onClick={downloadSearchRules}>
                    <Typography
                      variant={isMobile ? 'h3' : 'h4'}
                      fontWeight={{ xl: 400, xs: 600 }}
                      color={'inherit'}
                    >
                      here
                    </Typography>
                  </StyledUnderlineDownloadButtons>{' '}
                  to see the details of the search logic used on this website. Use Previous/Next
                  links to scroll through search results. Opening/Viewing a filing will position
                  that filing at the top of the search results list.
                </Typography>
              </Box>
              <SearchResults
                getUrl={getUrl}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                results={searchResults}
                lastSearchedKeyword={lastSearchedKeyword}
                isFetching={isFetchingSearchResults}
                handlePrint={handlePrint}
              />
            </Box>
          </Box>
        )}
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
