import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DebtorSearchResponse,
  SearchOptionType,
  UCCSearchCategory,
  UCCSearchOptionSubOption,
  UCCSearchOptionType,
} from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFileDetails,
  getFilingsCompletedThroughDate,
  getSearchOptions,
  getSearchResults,
} from './search.services';
import { useSearchDataFetch } from './useSearchDataFetch';
import { uccSearchDetailsPageURL, uccSearchPageURL } from '../../common/constants/routes';
import { ResponseMessageType } from '../shared/types';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  searchOptionType: yup.string().required('Search type is required.'),
  searchOptionSubOption: yup.string().when('searchOptionType', {
    is:
      UCCSearchOptionType.OrganizationDebtorName ||
      UCCSearchOptionType.LegacySearch ||
      UCCSearchOptionType.IndividualDebtorName,
    then: (schema) =>
      schema
        .required('Search sub-option type is required.')
        .oneOf(Object.values(UCCSearchOptionSubOption), 'Search sub-option type is required.'),
    otherwise: (schema) => schema.nullable(),
  }),
  searchCategory: yup.string().when(['searchOptionType', 'searchOptionSubOption'], {
    is: (searchOptionTypeValue: string, searchOptionSubOptionValue: string) =>
      searchOptionTypeValue === UCCSearchOptionType.OrganizationDebtorName &&
      (searchOptionSubOptionValue === UCCSearchOptionSubOption.LapsedCompactDebtorNameList ||
        searchOptionSubOptionValue === UCCSearchOptionSubOption.FiledCompactDebtorNameList ||
        searchOptionSubOptionValue ===
          UCCSearchOptionSubOption.FiledAndLapsedCompactDebtorNameList),
    then: (schema) =>
      schema
        .required('Search category type is required')
        .oneOf(Object.values(UCCSearchCategory), 'Search category type is required'),
    otherwise: (schema) => schema.optional(),
  }),
  keyword: yup.string().when('searchOptionType', {
    is: UCCSearchOptionType.DocumentNumber,
    then: (schema) =>
      schema
        .required('UCC number is required.')
        .max(12, 'UCC number cannot be more than 12 characters.'),
    otherwise: (schema) =>
      schema.required('Name is required.').max(100, 'Name cannot be more than 100 characters.'),
  }),
});

type FormStateType = yup.InferType<typeof schema>;

const mockSearchResponseNoDebtors = {
  status: 'OK',
  notOk: false,
  messages: [],
  payload: {
    debtors: [],
    nextRowNumber: 123,
    previousRowNumber: 124,
  },
  messageSummary: '',
  friendlyMessageSummary: '',
} as any as ResponseMessageType<DebtorSearchResponse>;

export const useSearchPage = () => {
  const navigate = useNavigate();
  const { data: options, isFetching: isFetchingOptions } =
    useSearchDataFetch<SearchOptionType[]>(getSearchOptions);
  const { data: throughDate, isFetching: isFetchingThroughDate } = useSearchDataFetch<string>(
    getFilingsCompletedThroughDate
  );
  const hasFinishedInitialFetches = !isFetchingOptions && !isFetchingThroughDate;
  const { rn, updateURL, getUrl, searchOptionType, searchOptionSubOption, searchCategory, text } =
    useSearchPageQueryParams();
  const form = useForm<FormStateType>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      keyword: text ?? '',
      searchOptionType: searchOptionType ?? UCCSearchOptionType.OrganizationDebtorName,
      searchOptionSubOption: searchOptionSubOption
        ? searchOptionSubOption
        : UCCSearchOptionSubOption.FiledCompactDebtorNameList,
      searchCategory: UCCSearchOptionType.OrganizationDebtorName
        ? (searchCategory as UCCSearchCategory) ?? UCCSearchCategory.Standard
        : undefined,
    },
  });

  const [lastSearchedKeyword, setLastSearchedKeyword] = useState('');

  const doSearch = useCallback(
    async (skipRN?: boolean) => {
      const keyword = form.getValues().keyword;
      const searchOptionTypeFormValue =
        form.getValues().searchOptionType ?? UCCSearchOptionType.OrganizationDebtorName;
      const searchOptionSubOptionFormValue =
        form.getValues().searchOptionSubOption ??
        UCCSearchOptionSubOption.FiledCompactDebtorNameList;
      const searchCategoryFormValue = form.watch('searchCategory');
      const rowNumber = !skipRN && rn ? parseInt(rn) : undefined;

      if (skipRN) {
        updateURL({
          text: keyword,
          searchOptionType: searchOptionTypeFormValue,
          searchOptionSubOption: searchOptionSubOptionFormValue,
          searchCategory: searchCategoryFormValue,
        });
      }

      if (searchOptionTypeFormValue !== UCCSearchOptionType.DocumentNumber) {
        const results = await getSearchResults({
          rowNumber,
          text: skipRN ? keyword : text ?? '',
          searchOptionType: skipRN
            ? searchOptionTypeFormValue
            : searchOptionType ?? UCCSearchOptionType.OrganizationDebtorName,
          searchOptionSubOption: searchOptionSubOptionFormValue
            ? searchOptionSubOptionFormValue ?? UCCSearchOptionSubOption.FiledActualDebtorNameList
            : UCCSearchOptionSubOption.FiledCompactDebtorNameList,
          searchCategory: searchCategoryFormValue,
        });

        if (!results.payload.debtors.length) {
          setLastSearchedKeyword(keyword);
        }
        return results;
      }

      try {
        await getFileDetails(UCCSearchOptionType.DocumentNumber, keyword);
        navigate(
          getUrl(uccSearchDetailsPageURL, {
            searchOptionType: searchOptionTypeFormValue,
            text: keyword,
            uccNumber: keyword,
          })
        );
        return mockSearchResponseNoDebtors;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e?.response?.status === 404) {
          setLastSearchedKeyword(keyword);
        }
        return new Promise<ResponseMessageType<DebtorSearchResponse>>((r) =>
          r(mockSearchResponseNoDebtors)
        );
      }
    },
    [form, getUrl, navigate, rn, searchOptionType, text, updateURL]
  );

  const {
    doFetch: search,
    data: searchResults,
    isFetching: isFetchingSearchResults,
  } = useSearchDataFetch<DebtorSearchResponse, boolean>(doSearch, false, true);

  useEffect(() => {
    if (
      hasFinishedInitialFetches &&
      !isFetchingSearchResults &&
      searchOptionType !== UCCSearchOptionType.DocumentNumber &&
      rn
    ) {
      search();
    }
  }, [hasFinishedInitialFetches, search, rn]);

  useEffect(() => {
    if (!options?.length || form.getValues('searchOptionType')) return;
    form.setValue('searchOptionType', options[0].id);
  }, [form, options]);

  const handleSubmit = form.handleSubmit(async () => {
    if (!isFetchingSearchResults) {
      await search(true);
    }
  });

  const keyword = form.watch('keyword');
  const searchOptionTypeValue = form.watch('searchOptionType');
  const searchOptionSubTypeValue = form.watch('searchOptionSubOption');

  const searchCategoryValue = form.watch('searchCategory');
  const nextUrl = useMemo(() => {
    if (
      !searchResults?.nextRowNumber ||
      searchResults?.nextRowNumber ===
        (searchResults.debtors[searchResults.debtors.length - 1]?.rowNumber ?? -1) ||
      searchResults.nextRowNumber === Number(rn)
    ) {
      return null;
    }

    return getUrl(uccSearchPageURL, { rn: searchResults?.nextRowNumber?.toString() });
  }, [getUrl, searchResults?.debtors, searchResults?.nextRowNumber]);
  const prevUrl = useMemo(() => {
    if (
      !searchResults?.previousRowNumber ||
      (searchResults?.previousRowNumber === searchResults.debtors[0]?.rowNumber ?? -1)
    )
      return null;

    return getUrl(uccSearchPageURL, { rn: searchResults?.previousRowNumber?.toString() });
  }, [getUrl, searchResults?.debtors, searchResults?.previousRowNumber]);

  return {
    hasFinishedInitialFetches,
    throughDate,
    options,
    ...form,
    getUrl,
    nextUrl,
    prevUrl,
    searchOptionTypeValue,
    searchOptionSubTypeValue,
    searchCategoryValue,
    keyword,
    handleSubmit,
    searchResults,
    isFetchingSearchResults,
    form,
    lastSearchedKeyword,
  };
};
