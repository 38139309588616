import { getFileDetails } from '../search.services';
import { useSearchDataFetch } from '../useSearchDataFetch';

export const useDebtorPartiesPage = (
  searchOptionsType: string,
  document?: string,
  rn?: string,
  searchOptionSubOption?: string,
  searchCategory?: string,
  text?: string
) => {
  const { data: fileData, isFetching: isLoading } = useSearchDataFetch(
    () =>
      getFileDetails(searchOptionsType, document, rn, searchOptionSubOption, searchCategory, text),
    true
  );

  return { fileData, isLoading };
};
